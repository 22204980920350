import '../styles/globals.css';
import 'react-notion-x/src/styles.css'; // core styles shared by all of react-notion-x (required)
import type { AppProps } from 'next/app';
import { SearchContextProvider } from '../contexts/SearchContext';
import { UserContextProvider } from '../contexts/UserContext';
import { MapContextProvider } from '../contexts/MapContext';
import { MapProvider } from 'react-map-gl';
import useCaptureScreenViewsForAnalytics from '../hooks/useCaptureScreenViewsForAnalytics';
import useSaveUtmParamsToLocalStorageAndCreateInitialAnalyticsEvent from '../hooks/useSaveUtmParamsToLocalStorageAndCreateInitialAnalyticsEvent';
import { AuthModalContextProvider } from '../contexts/AuthModalContext';
import AuthModal from '../components/AuthModal/AuthModal';
import SearchFilterModal from '../components/searchFilters/SearchFilterModal';
import QueryClientProvider from '../contexts/QueryClientProvider';

import '@th/shared/src/jsTypeExtensions/numberAsPriceExtension';

function MyApp({ Component, pageProps }: AppProps) {
  useSaveUtmParamsToLocalStorageAndCreateInitialAnalyticsEvent();

  useCaptureScreenViewsForAnalytics();

  return (
    <QueryClientProvider>
      <AuthModalContextProvider>
        <UserContextProvider>
          <SearchContextProvider>
            <MapProvider>
              <MapContextProvider>
                <Component {...pageProps} />
                <AuthModal />
                <SearchFilterModal />
              </MapContextProvider>
            </MapProvider>
          </SearchContextProvider>
        </UserContextProvider>
      </AuthModalContextProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
