import { UtmParams } from '../../types/UtmParams';

const UTM_PARAM_KEYS = [
  {
    queryParamKey: 'utm_source',
    internalKey: 'utmSource',
  },
  {
    queryParamKey: 'utm_medium',
    internalKey: 'utmMedium',
  },
  {
    queryParamKey: 'utm_campaign',
    internalKey: 'utmCampaign',
  },
  {
    queryParamKey: 'utm_term',
    internalKey: 'utmTerm',
  },
  {
    queryParamKey: 'utm_content',
    internalKey: 'utmContent',
  },
];

export default function getUtmParamsFromUrl(fullUrl: string): UtmParams | null {
  const url = new URL(fullUrl);

  const utmParams = {};

  UTM_PARAM_KEYS.forEach(utmParamKey => {
    const queryParamValue = url.searchParams.get(utmParamKey.queryParamKey);
    if (queryParamValue) {
      utmParams[utmParamKey.internalKey] = queryParamValue;
    }
  });

  return Object.values(utmParams).length > 0 ? utmParams : null;
}
