'use client';

import React, { createContext, useState } from 'react';

type AuthModalContext = {
  isOpen: boolean;
  openAuthModal: () => void;
  closeAuthModal: () => void;
};

// ----------------------
// Business Logic
// ----------------------

function useAuthModalLogic() {
  const [isOpen, setIsOpen] = useState(false);

  function openAuthModal() {
    setIsOpen(true);
  }

  function closeAuthModal() {
    setIsOpen(false);
  }

  return {
    isOpen,
    openAuthModal,
    closeAuthModal,
  };
}

// ----------------------
// BoilerPlate below
// ----------------------

const AuthModalContext = createContext<AuthModalContext | undefined>(undefined);

function AuthModalContextProvider({ children }) {
  const authModalContext = useAuthModalLogic();
  return (
    <AuthModalContext.Provider value={authModalContext}>
      {children}
    </AuthModalContext.Provider>
  );
}

function useAuthModal() {
  const context = React.useContext(AuthModalContext);
  if (context === undefined) {
    throw new Error(
      'useAuthModal must be used within a AuthModalContextProvider',
    );
  }
  return context;
}

export { AuthModalContextProvider, useAuthModal };
