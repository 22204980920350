'use client';

// Condo Amenities/Utilities
import MdBbq from './materialDesign/mdi-bbq-outline.svg';
import MdCheck from './materialDesign/mdi-check.svg';
import FluentBalloons from './fluent/fluent-mdl2-balloons.svg';
import MdBed from './materialDesign/mdi-bed-outline.svg';
import InGym from './iconoir/iconoir-gym.svg';
import MdTelevision from './materialDesign/mdi-television-classic.svg';
import FluentParking from './fluent/fluent-vehicle-car-parking-20-regular.svg';
import MsRoofedTable from './materialSymbols/material-symbols-deck-outline.svg';
import MdDice from './materialDesign/mdi-dice-multiple-outline.svg';
import FluentFax from './fluent/fluent-mdl2-fax.svg';
import MsConciergeBell from './materialSymbols/material-symbols-concierge-outline.svg';
import FaChess from './fontAwesome/fa6-solid-chess-board.svg';
import FaThinSnowflake from './fontAwesome/fa6-regular-snowflake.svg';
import MdFlame from './materialDesign/mdi-flame.svg';
import MdLightningBolt from './materialDesign/mdi-lightning-bolt-outline.svg';

// Other
import AdDelete from './antDesign/ad-delete-outlined.svg';
import BiHourglassSplit from './bootstrap/bi-hourglass-split.svg';
import BxShapeSquare from './boxIcons/bx-shape-square.svg';
import BxSlider from './boxIcons/bx-slider-alt.svg';
import CarbonCheckmark from './carbon/carbon-checkmark-filled.svg';
import CgCheckO from './cssGg/cg-check-o.svg';
import CgChevronDoubleLeftO from './cssGg/cg-chevron-double-left-o.svg';
import CgChevronDoubleRightO from './cssGg/cg-chevron-double-right-o.svg';
import CgChevronLeftO from './cssGg/cg-chevron-left-o.svg';
import CgChevronRightO from './cssGg/cg-chevron-right-o.svg';
import CgCloseO from './cssGg/cg-close-o.svg';
import FaCircleCheck from './fontAwesome/fa-circle-check.svg';
import FaBell from './fontAwesome/fa-reg-bell.svg';
import FaSolidBath from './fontAwesome/fa-solid-bath.svg';
import FaSolidBed from './fontAwesome/fa-solid-bed.svg';
import FaSolidCalculator from './fontAwesome/fa-solid-calculator.svg';
import FaSolidCar from './fontAwesome/fa-solid-car.svg';
import FaSolidCity from './fontAwesome/fa-solid-city.svg';
import FaSolidGraduationCap from './fontAwesome/fa6-solid-graduation-cap.svg';
import FaSolidHome from './fontAwesome/fa-solid-home.svg';
import FaSolidRulerCombined from './fontAwesome/fa-solid-ruler-combined.svg';
import FaSolidSnowflake from './fontAwesome/fa-solid-snowflake.svg';
import FaSolidSquareParking from './fontAwesome/fa-solid-square-parking.svg';
import FaSolidSwimmingPool from './fontAwesome/fa-solid-swimming-pool.svg';
import FaSolidThermometerThreeQuarters from './fontAwesome/fa-solid-thermometer-three-quarters.svg';
import FaSolidWrench from './fontAwesome/fa-solid-wrench.svg';
import FoShoppingSale from './fontisto/shopping-sale.svg';
import GiFamilyHouse from './gameIcons/gi-family-house.svg';
import GmOutlineInput from './googleMaterialIcons/gm-outline-input.svg';
import HiArrowTopRightOnSquare from './heroIcons/hi-arrow-top-right-on-square.svg';
import HiBars3 from './heroIcons/hi-bars-3.svg';
import HiChevronDown from './heroIcons/hi-chevron-down.svg';
import HiChevronLeft from './heroIcons/hi-chevron-left.svg';
import HiChevronRight from './heroIcons/hi-chevron-right.svg';
import HiChevronUp from './heroIcons/hi-chevron-up.svg';
import HiLockClosed from './heroIcons/hi-lock-closed.svg';
import HiMagnifyingGlass from './heroIcons/hi-magnifying-glass.svg';
import HiPlus from './heroIcons/hi-plus.svg';
import HiX from './heroIcons/hi-x-mark.svg';
import ImCompass from './icoMoon/im-compass-2.svg';
import IoDice from './ionIcons/io-dice-outline.svg';
import MdAccountTieWoman from './materialDesign/md-account-tie-woman.svg';
import MdHomeClock from './materialDesign/md-home-clock-outline.svg';
import MdStairsBox from './materialDesign/md-stairs-box.svg';
import MdTrendingDown from './materialDesign/md-trending-down.svg';
import MdTrendingUp from './materialDesign/md-trending-up.svg';
import MsAltOff from './materialSymbols/ms-alt-off-outline.svg';
import MsWater from './materialSymbols/ms-water.svg';
import GoDotFill from './octicons/go-dot-fill.svg';
import GoFilter from './octicons/go-filter-16.svg';
import PhCalendarCheck from './phosphor/ph-calendar-check-duotone.svg';
import PhHandshake from './phosphor/ph-handshake-fill.svg';
import PhPaintbrushHousehold from './phosphor/ph-paint-brush-household-fill.svg';
import TbClipboard from './tabler/tb-clipboard.svg';
import TbInfoCircle from './tabler/tb-info-circle.svg';
import TbMap from './tabler/tb-map.svg';
import TbMapLowerRightPin from './tabler/tb-map-2.svg';
import TbMapPin from './tabler/tb-map-pin.svg';
import ZondPylon from './zondIcons/zond-pylon.svg';

import { twMerge } from 'tailwind-merge';

/*
  We import icons individually to reduce bundle size. It's a bit more work but the reduction to bundle size is worth it.

  To add new icons:
  1. Visit https://icon-sets.iconify.design/
  2. Search for the icon you want
  3. Click on the icon
  4. Under "Code for _ for developers:", select "SVG", and "SVG"
  5. Copy the svg code (there is a copy to clipboard button if you hover)
  6. Create a new file in this directory with the name of the icon
  7. Paste the svg code into the file
  8. Remove the height and width from the svg tag
  9. Add the icon to the switch statement below
 */

export type IconName =
  // Condo Amenities/Utilities
  | 'MdBbq'
  | 'MdCheck'
  | 'FluentBalloons'
  | 'MdBed'
  | 'InGym'
  | 'MdTelevision'
  | 'FluentParking'
  | 'MsRoofedTable'
  | 'MdDice'
  | 'FluentFax'
  | 'MsConciergeBell'
  | 'FaChess'
  | 'FaThinSnowflake'
  | 'MdFlame'
  | 'MdLightningBolt'

  // Other
  | 'AdDelete'
  | 'BiHourglassSplit'
  | 'BxShapeSquare'
  | 'BxSlider'
  | 'CarbonCheckmark'
  | 'CgCheckO'
  | 'CgChevronDoubleLeftO'
  | 'CgChevronDoubleRightO'
  | 'CgChevronLeftO'
  | 'CgChevronRightO'
  | 'CgCloseO'
  | 'FaCircleCheck'
  | 'FaBell'
  | 'FaSolidBath'
  | 'FaSolidBed'
  | 'FaSolidCalculator'
  | 'FaSolidCar'
  | 'FaSolidCity'
  | 'FaSolidGraduationCap'
  | 'FaSolidHome'
  | 'FaSolidRulerCombined'
  | 'FaSolidSnowflake'
  | 'FaSolidSquareParking'
  | 'FaSolidSwimmingPool'
  | 'FaSolidThermometerThreeQuarters'
  | 'FaSolidWrench'
  | 'FoShoppingSale'
  | 'GiFamilyHouse'
  | 'GmOutlineInput'
  | 'HiArrowTopRightOnSquare'
  | 'HiBars3'
  | 'HiChevronDown'
  | 'HiChevronLeft'
  | 'HiChevronRight'
  | 'HiChevronUp'
  | 'HiLockClosed'
  | 'HiMagnifyingGlass'
  | 'HiPlus'
  | 'HiX'
  | 'ImCompass'
  | 'IoDice'
  | 'MdAccountTieWoman'
  | 'MdHomeClock'
  | 'MdStairsBox'
  | 'MdTrendingDown'
  | 'MdTrendingUp'
  | 'MsAltOff'
  | 'MsWater'
  | 'GoDotFill'
  | 'GoFilter'
  | 'PhCalendarCheck'
  | 'PhHandshake'
  | 'PhPaintbrushHousehold'
  | 'TbClipboard'
  | 'TbInfoCircle'
  | 'TbMap'
  | 'TbMapLowerRightPin'
  | 'TbMapPin'
  | 'ZondPylon';

export type SvgIconProps = {
  name: IconName;
  className?: string;
  style?: React.CSSProperties;
};

export default function SvgIcon({
  name,
  className,
  style,
  ...props
}: SvgIconProps) {
  let Icon;
  switch (name) {
    // Condo Amenities/Utilities
    case 'MdBbq':
      Icon = MdBbq;
      break;
    case 'MdCheck':
      Icon = MdCheck;
      break;
    case 'FluentBalloons':
      Icon = FluentBalloons;
      break;
    case 'MdBed':
      Icon = MdBed;
      break;
    case 'InGym':
      Icon = InGym;
      break;
    case 'MdTelevision':
      Icon = MdTelevision;
      break;
    case 'FluentParking':
      Icon = FluentParking;
      break;
    case 'MsRoofedTable':
      Icon = MsRoofedTable;
      break;
    case 'MdDice':
      Icon = MdDice;
      break;
    case 'FluentFax':
      Icon = FluentFax;
      break;
    case 'MsConciergeBell':
      Icon = MsConciergeBell;
      break;
    case 'FaChess':
      Icon = FaChess;
      break;
    case 'FaThinSnowflake':
      Icon = FaThinSnowflake;
      break;
    case 'MdFlame':
      Icon = MdFlame;
      break;
    case 'MdLightningBolt':
      Icon = MdLightningBolt;
      break;

    // Other
    case 'AdDelete':
      Icon = AdDelete;
      break;
    case 'BiHourglassSplit':
      Icon = BiHourglassSplit;
      break;
    case 'BxShapeSquare':
      Icon = BxShapeSquare;
      break;
    case 'BxSlider':
      Icon = BxSlider;
      break;
    case 'CarbonCheckmark':
      Icon = CarbonCheckmark;
      break;
    case 'CgCheckO':
      Icon = CgCheckO;
      break;
    case 'CgChevronDoubleLeftO':
      Icon = CgChevronDoubleLeftO;
      break;
    case 'CgChevronDoubleRightO':
      Icon = CgChevronDoubleRightO;
      break;
    case 'CgChevronLeftO':
      Icon = CgChevronLeftO;
      break;
    case 'CgChevronRightO':
      Icon = CgChevronRightO;
      break;
    case 'CgCloseO':
      Icon = CgCloseO;
      break;
    case 'FaCircleCheck':
      Icon = FaCircleCheck;
      break;
    case 'FaBell':
      Icon = FaBell;
      break;
    case 'FaSolidBath':
      Icon = FaSolidBath;
      break;
    case 'FaSolidBed':
      Icon = FaSolidBed;
      break;
    case 'FaSolidCalculator':
      Icon = FaSolidCalculator;
      break;
    case 'FaSolidCar':
      Icon = FaSolidCar;
      break;
    case 'FaSolidCity':
      Icon = FaSolidCity;
      break;
    case 'FaSolidGraduationCap':
      Icon = FaSolidGraduationCap;
      break;
    case 'FaSolidHome':
      Icon = FaSolidHome;
      break;
    case 'FaSolidRulerCombined':
      Icon = FaSolidRulerCombined;
      break;
    case 'FaSolidSnowflake':
      Icon = FaSolidSnowflake;
      break;
    case 'FaSolidSquareParking':
      Icon = FaSolidSquareParking;
      break;
    case 'FaSolidSwimmingPool':
      Icon = FaSolidSwimmingPool;
      break;
    case 'FaSolidThermometerThreeQuarters':
      Icon = FaSolidThermometerThreeQuarters;
      break;
    case 'FoShoppingSale':
      Icon = FoShoppingSale;
      break;
    case 'FaSolidWrench':
      Icon = FaSolidWrench;
      break;
    case 'GiFamilyHouse':
      Icon = GiFamilyHouse;
      break;
    case 'GmOutlineInput':
      Icon = GmOutlineInput;
      break;
    case 'HiArrowTopRightOnSquare':
      Icon = HiArrowTopRightOnSquare;
      break;
    case 'HiBars3':
      Icon = HiBars3;
      break;
    case 'HiChevronDown':
      Icon = HiChevronDown;
      break;
    case 'HiChevronLeft':
      Icon = HiChevronLeft;
      break;
    case 'HiChevronRight':
      Icon = HiChevronRight;
      break;
    case 'HiChevronUp':
      Icon = HiChevronUp;
      break;
    case 'HiLockClosed':
      Icon = HiLockClosed;
      break;
    case 'HiMagnifyingGlass':
      Icon = HiMagnifyingGlass;
      break;
    case 'HiPlus':
      Icon = HiPlus;
      break;
    case 'HiX':
      Icon = HiX;
      break;
    case 'ImCompass':
      Icon = ImCompass;
      break;
    case 'IoDice':
      Icon = IoDice;
      break;
    case 'MdAccountTieWoman':
      Icon = MdAccountTieWoman;
      break;
    case 'MdHomeClock':
      Icon = MdHomeClock;
      break;
    case 'MdStairsBox':
      Icon = MdStairsBox;
      break;
    case 'MdTrendingDown':
      Icon = MdTrendingDown;
      break;
    case 'MdTrendingUp':
      Icon = MdTrendingUp;
      break;
    case 'MsAltOff':
      Icon = MsAltOff;
      break;
    case 'MsWater':
      Icon = MsWater;
      break;
    case 'GoDotFill':
      Icon = GoDotFill;
      break;
    case 'GoFilter':
      Icon = GoFilter;
      break;
    case 'PhCalendarCheck':
      Icon = PhCalendarCheck;
      break;
    case 'PhHandshake':
      Icon = PhHandshake;
      break;
    case 'PhPaintbrushHousehold':
      Icon = PhPaintbrushHousehold;
      break;
    case 'TbClipboard':
      Icon = TbClipboard;
      break;
    case 'TbInfoCircle':
      Icon = TbInfoCircle;
      break;
    case 'TbMap':
      Icon = TbMap;
      break;
    case 'TbMapLowerRightPin':
      Icon = TbMapLowerRightPin;
      break;
    case 'TbMapPin':
      Icon = TbMapPin;
      break;
    case 'ZondPylon':
      Icon = ZondPylon;
      break;
    default:
      Icon = HiX;
      break;
  }

  return (
    <Icon className={twMerge('h-6 w-6', className)} style={style} {...props} />
  );
}
