import {
  AnalyticsEventAction,
  AnalyticsEventType,
} from '@th/shared/src/types/AnalyticsEvent';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import logEvent from '../helpers/mutations/logEvent';

export default function useCaptureScreenViewsForAnalytics() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = url => {
      logEvent({
        action: AnalyticsEventAction.viewed_screen,
        type: AnalyticsEventType.screen_view,
      });
    };

    // NOTE: the listener below will not fire on the initial route (only for subsequent route changes)
    // The useSaveUtmParamsToLocalStorageAndCreateInitialAnalyticsEvent function deals with the initial route
    router.events.on('routeChangeComplete', handleRouteChange);

    // Unsubscribe from router events when the component unmounts:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
