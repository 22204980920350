import AxiosClient from '@th/frontend-shared/src/utils/AxiosClient';
import logAxiosError from '@th/frontend-shared/src/utils/logAxiosError';
import { Platform } from '@th/shared/src/types/Platform';
import getAndStoreDeviceFingerprintId from '../getAndStoreDeviceFingerprintId';

export default async function upsertDevice() {
  const deviceId = await getAndStoreDeviceFingerprintId();

  const endpoint = `/api/devices/${deviceId}`;
  const payload = {
    platform: Platform.Web,
  };

  try {
    AxiosClient.put(endpoint, payload);
  } catch (err) {
    logAxiosError(`upsertDevice PUT ${endpoint}`, err, payload);
  }
}
