import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCcQAbkYPW31Y0GdpjAQKzOLFLGiu53L3Q',
  authDomain: 'tophouse-e4ace.firebaseapp.com',
  projectId: 'tophouse-e4ace',
  storageBucket: 'tophouse-e4ace.appspot.com',
  messagingSenderId: '79658152191',
  appId: '1:79658152191:web:7b10aa9e02a0534de11cca',
  measurementId: 'G-M98BRE11BQ',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

let analytics;
if (app.name && typeof window !== 'undefined') {
  // Next.js SSR doesn't have window
  analytics = getAnalytics(app);
}

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
}

export { auth, analytics };
