import axios from 'axios';
import appUrl from '../config/appUrl';
import { auth } from './firebase';

const AxiosClient = axios.create({
  baseURL: appUrl,
  timeout: 10000,
});

AxiosClient.interceptors.request.use(async function (config) {
  try {
    const jwt = await auth.currentUser.getIdToken();
    config.headers.Authorization = `Bearer ${jwt}`;
  } catch (err) {
    // User is not signed in. That's ok
  }
  return config;
});

export default AxiosClient;
