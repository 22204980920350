import AxiosClient from '@th/frontend-shared/src/utils/AxiosClient';
import logAxiosError from '@th/frontend-shared/src/utils/logAxiosError';
import { AnalyticsEventBaseInfo } from '@th/shared/src/types/AnalyticsEvent';
import getAndStoreDeviceFingerprintId from '../getAndStoreDeviceFingerprintId';
import { getUtmParamsFromStorage } from '../localStorage/utmParams';

export default async function logEvent(
  analyticsEventBaseInfo: AnalyticsEventBaseInfo,
) {
  const endpoint = `/api/v1/analyticsEvents`;

  try {
    const fallbackWebUrl = window.location.href;
    const deviceId = await getAndStoreDeviceFingerprintId();
    const utmParams = getUtmParamsFromStorage() || {};

    const payload = {
      deviceId,
      ...utmParams,
      ...analyticsEventBaseInfo,
      webUrl: analyticsEventBaseInfo.webUrl || fallbackWebUrl,
    };

    AxiosClient.post(endpoint, payload);
  } catch (err) {
    logAxiosError(`createAnalyticsEvent POST ${endpoint}`, err);
  }
}
