import { UtmParams } from '@th/shared/src/types/UtmParams';
import { STORAGE_UTM_PARAMS_KEY } from '../../constants/localStorage';

export function setUtmParamsInStorage(utmParams: UtmParams) {
  if (Object.values(utmParams).length > 0) {
    window.localStorage.setItem(
      STORAGE_UTM_PARAMS_KEY,
      JSON.stringify(utmParams),
    );
  }
}

export function getUtmParamsFromStorage(): UtmParams | null {
  const stringifiedUtmParams = window.localStorage.getItem(
    STORAGE_UTM_PARAMS_KEY,
  );

  if (!stringifiedUtmParams) return null;

  const utmParams = JSON.parse(stringifiedUtmParams);
  if (utmParams && Object.values(utmParams).length > 0) {
    return utmParams;
  }

  return null;
}
