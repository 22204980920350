import { instanceToPlain } from 'class-transformer';
import GpsCoordinates from './GpsCoordinates';

export enum SchoolType {
  Public = 'Public',
  Private = 'Private',
  Catholic = 'Catholic',
}

export enum SchoolLevel {
  Elementary = 'Elementary',
  Secondary = 'Secondary',
}

export type SchoolObject = {
  name: string;
  level: SchoolLevel;
  type: SchoolType;
  rawAddress: string;
  address: string | null;
  city: string | null;
  schoolDistrict: string | null;
  rating: number | null;
  ranking: string | null;
  phone: string | null;
  website: string | null;
  eslPercentage: number | null;
  specialNeedsPercentage: number | null;
  ossltCount: number | null;
  grade6Enrolment: number | null;
  fiUrl: string | null;
  coordinates: GpsCoordinates | null;
};

export type SchoolRecord = {
  name: string;
  level: SchoolLevel;
  type: SchoolType;
  rawAddress: string;
  address: string | null;
  city: string | null;
  schoolDistrict: string | null;
  rating: number | null;
  ranking: string | null;
  phone: string | null;
  website: string | null;
  eslPercentage: number | null;
  specialNeedsPercentage: number | null;
  ossltCount: number | null;
  grade6Enrolment: number | null;
  fiUrl: string | null;
  point: GpsCoordinates | null;
};

export class School {
  name: string;
  level: SchoolLevel;
  type: SchoolType;
  rawAddress: string;
  address: string | null;
  city: string | null;
  schoolDistrict: string | null;
  rating: number | null;
  ranking: string | null;
  phone: string | null;
  website: string | null;
  eslPercentage: number | null;
  specialNeedsPercentage: number | null;
  ossltCount: number | null;
  grade6Enrolment: number | null;
  fiUrl: string | null;
  coordinates: GpsCoordinates | null;

  static fromObject(schoolObject: SchoolObject) {
    return new School(schoolObject);
  }

  static fromRecord(schoolRecord: SchoolRecord) {
    return new School({
      ...schoolRecord,
      coordinates: schoolRecord.point,
    });
  }

  constructor(schoolObject: SchoolObject) {
    this.name = schoolObject.name;
    this.level = schoolObject.level;
    this.type = schoolObject.type;
    this.rawAddress = schoolObject.rawAddress;
    this.address = schoolObject.address;
    this.city = schoolObject.city;
    this.schoolDistrict = schoolObject.schoolDistrict;
    this.rating = schoolObject.rating;
    this.ranking = schoolObject.ranking;
    this.phone = schoolObject.phone;
    this.website = schoolObject.website;
    this.eslPercentage = schoolObject.eslPercentage;
    this.specialNeedsPercentage = schoolObject.specialNeedsPercentage;
    this.ossltCount = schoolObject.ossltCount;
    this.grade6Enrolment = schoolObject.grade6Enrolment;
    this.fiUrl = schoolObject.fiUrl;
    this.coordinates = schoolObject.coordinates;
  }

  toRecord(): SchoolRecord {
    return {
      ...instanceToPlain(this),
      point: this.coordinates,
    } as SchoolRecord;
  }

  toObject(): SchoolObject {
    return instanceToPlain(this) as SchoolObject;
  }
}
