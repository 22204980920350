import getFirstName from '../helpers/names/getFirstName';
import getLastName from '../helpers/names/getLastName';
import { User, UserObject } from './User';
import isDefinedAndNonNull from '../utils/isDefinedAndNonNull';
import { AgentVerificationStatus } from './AgentVerificationStatus';
import { instanceToPlain } from 'class-transformer';

export type AgentObject = UserObject & {
  agentId?: string;
  username: string;
  legalName: string;
  brokerageName: string;
  brokeragePhoneNumber?: string;
  verificationStatus?: AgentVerificationStatus;
  fubKey?: string;
};

export type AgentRecord = AgentObject;

export class Agent extends User {
  agentId?: string;
  username: string;
  legalName: string;
  brokerageName: string;
  brokeragePhoneNumber?: string;
  verificationStatus?: AgentVerificationStatus;
  fubKey?: string;

  static fromObject(agentObject: AgentObject) {
    return new Agent(agentObject);
  }

  static fromRecord(agentRecord: AgentRecord) {
    return new Agent(agentRecord);
  }

  constructor(agentObject: AgentObject) {
    super(agentObject);

    if (agentObject.agentId) {
      this.agentId = agentObject.agentId;
    }
    this.username = agentObject.username;
    this.legalName = agentObject.legalName;
    this.brokerageName = agentObject.brokerageName;
    this.brokeragePhoneNumber = agentObject.brokeragePhoneNumber;
    this.verificationStatus = isDefinedAndNonNull(
      agentObject.verificationStatus,
    )
      ? agentObject.verificationStatus
      : AgentVerificationStatus.Pending;
    if (agentObject.fubKey) {
      this.fubKey = agentObject.fubKey;
    }
  }

  isAgent(): this is Agent {
    return true;
  }

  isVerified(): boolean {
    return this.verificationStatus === AgentVerificationStatus.Verified;
  }

  isVerifiedAgent(): boolean {
    return (
      this.isAgent() &&
      this.verificationStatus === AgentVerificationStatus.Verified
    );
  }

  isPendingAgent(): boolean {
    return (
      this.isAgent() &&
      this.verificationStatus === AgentVerificationStatus.Pending
    );
  }

  firstName(): string | null {
    const userFirstName = super.firstName();

    return userFirstName || this.legalFirstName();
  }

  lastName(): string | null {
    return this.legalLastName();
  }

  legalFirstName(): string | null {
    return getFirstName(this.legalName);
  }

  legalLastName(): string | null {
    return getLastName(this.legalName);
  }

  isUnclaimedAccount(): boolean {
    return !this.firebaseId;
  }

  toObject(): AgentObject {
    return instanceToPlain(this) as AgentObject;
  }
}
