import { RepliersListingTransactionType } from '../types/repliers/RepliersListingTransactionType';
import { Units } from '../types/Units';
import { SearchFilters } from '../types/SearchFilters';
import pick from 'lodash.pick';
import { ListDateFilterLabels } from '../deprecated/ListDateFilterLabels';
import { SoldDateFilterLabels } from '../deprecated/SoldDateFilterLabels';

export const FOR_SALE_MAX_PRICE = 5_000_000;
export const FOR_LEASE_MAX_PRICE = 10_000;
export const FOR_SALE_STEP = 100_000;
export const FOR_LEASE_STEP = 100;

export const MAX_BUILDING_AGE_YEARS = 100;

export const MAX_SQFT = 5000;

export const defaultFilters: Required<SearchFilters> = {
  showSoldOrLeased: true,
  showActive: true,
  transactionType: RepliersListingTransactionType.Sale,
  minPrice: 0,
  maxPrice: null,
  numBedrooms: {
    Any: true,
    '0': false,
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5+': false,
  },
  numBathrooms: {
    Any: true,
    '0': false,
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5+': false,
  },
  minLotFront: 0,
  maxLotFront: null,
  lotMeasurementUnits: Units.Feet,
  propertyTypes: [],
  styles: [],
  balconies: [],
  condoExposures: [],
  maxListedDaysAgo: null,
  maxSoldDaysAgo: 7, // 1 week
  onlyShowPriceChangedStatus: false,
  neighborhoodName: null,
  hasPool: null,
  hasBackyard: null,
  hasBasement: null,
  hasFinishedBasement: null,
  hasSeparateEntranceBasement: null,
  hasBasementApartment: null,
  isOnWaterfront: null,
  isFixerUpper: null,
  hasOfferDate: null,
  keywords: [],
  minMaintenanceFee: 0,
  maxMaintenanceFee: null,
  minGarageSpaces: 0,
  maxGarageSpaces: null,
  minParkingSpaces: 0,
  maxParkingSpaces: null,
  minBedrooms: 0,
  maxBedrooms: null,
  hasDen: null,
  minBathrooms: 0,
  maxBathrooms: null,
  minListDate: null,
  maxListDate: null,
  minSoldDate: null,
  maxSoldDate: null,
  minImages: 0,
  hasSupportedVirtualTourUrl: null,
  cities: [],
  districts: [],
  areas: [],
  lastStatuses: [],
  geographicAreas: [],
  buildingClasses: [],
  minOfferCount: null,
  maxOfferCount: null,
  minOfferDate: null,
  maxOfferDate: null,
  maxDistanceToSubwayLinesMetres: null,
  minBuildingAgeYears: 0,
  maxBuildingAgeYears: null,
  isForSaleByOwner: null,
  isPowerOfSale: null,
  minSquareFeet: 0,
  maxSquareFeet: null,

  // @deprecated
  listDate: ListDateFilterLabels.Any,
  soldDate: SoldDateFilterLabels.ThreeMonths,
};

// These are filters that are quite coupled to the app and need to be set for lots of map functionality to work
export const coreDefaultSearchFilters = pick(defaultFilters, [
  'showSoldOrLeased',
  'showActive',
  'transactionType',
  'maxSoldDaysAgo',
]);
