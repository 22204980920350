import { SearchFilters } from '../../types/SearchFilters';

export default function removeGeoJsonFeatureIfAreaIdExists(
  searchFilters: SearchFilters,
): SearchFilters {
  const { geographicAreas, ...restOfSearchFilters } = searchFilters;

  const searchFiltersWithGeoJsonFeatureRemoved: SearchFilters = {
    ...restOfSearchFilters,
  };

  if (geographicAreas) {
    searchFiltersWithGeoJsonFeatureRemoved.geographicAreas =
      geographicAreas.map(geographicArea => {
        const { areaId, geoJsonFeature, ...restOfGeographicArea } =
          geographicArea;
        if (areaId) {
          return {
            areaId,
            ...restOfGeographicArea,
          };
        } else {
          return geographicArea;
        }
      });
  }

  return searchFiltersWithGeoJsonFeatureRemoved;
}
