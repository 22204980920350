import { roundToNearestHundred, roundToNearestThousand } from '../utils/math';

const ONE_MILLION = 1_000_000;
const TEN_THOUSAND = 10_000;
const ONE_THOUSAND = 1_000;

const ONE_MILLION_SUFFIX = 'M';
const ONE_THOUSAND_SUFFIX = 'K';

const ONE_DECIMAL = 1;
const NO_DECIMALS = 0;

declare global {
  interface Number {
    formatAsPrice: () => string;
    formatAsShortPrice: () => string;
  }
}

Number.prototype.formatAsPrice = function (): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(this as number);
};

Number.prototype.formatAsShortPrice = function (): string {
  if (roundToNearestThousand(this) >= ONE_MILLION) {
    const reducedNumberString = ((this as number) / ONE_MILLION).toFixed(
      ONE_DECIMAL,
    );
    return `$${reducedNumberString}${ONE_MILLION_SUFFIX}`;
  } else if (roundToNearestHundred(this) >= TEN_THOUSAND) {
    const reducedNumberString = ((this as number) / ONE_THOUSAND).toFixed(
      NO_DECIMALS,
    );
    return `$${reducedNumberString}${ONE_THOUSAND_SUFFIX}`;
  } else if (this >= ONE_THOUSAND) {
    const reducedNumberString = ((this as number) / ONE_THOUSAND).toFixed(
      ONE_DECIMAL,
    );
    return `$${reducedNumberString}${ONE_THOUSAND_SUFFIX}`;
  } else {
    return `$${this.toFixed(0)}`;
  }
};

export {}; // This line is needed in order to use the `declare global` syntax
