'use client';

import dynamic from 'next/dynamic';
import ModalWithOverlay from '../ModalWithOverlay';
import { useAuthModal } from '../../contexts/AuthModalContext';
import { useEffect, useState } from 'react';

type Props = {};

type AuthSteps = 'PHONE_LOGIN' | 'EMAIL_INPUT' | 'WAITING_FOR_EMAIL_LINK';

const FIRST_STEP = 'PHONE_LOGIN';

const AuthModalPhoneInputStep = dynamic(
  () => import('./AuthModalPhoneInputStep'),
  {},
);

const AuthModalEmailInputStep = dynamic(
  () => import('./AuthModalEmailInputStep'),
  {},
);

const AuthModalCheckEmailStep = dynamic(
  () => import('./AuthModalCheckEmailStep'),
  {},
);

export default function AuthModal({}: Props) {
  const { isOpen, closeAuthModal } = useAuthModal();

  const [step, setStep] = useState<AuthSteps>(FIRST_STEP);

  useEffect(() => {
    // Start again if the modal is closed and reopened
    if (isOpen) {
      setStep(FIRST_STEP);
    }
  }, [isOpen]);

  return (
    <ModalWithOverlay isOpen={isOpen} onClose={closeAuthModal}>
      {step === 'PHONE_LOGIN' && (
        <AuthModalPhoneInputStep
          onClickEmailLogin={() => setStep('EMAIL_INPUT')}
        />
      )}
      {step === 'EMAIL_INPUT' && (
        <AuthModalEmailInputStep
          onClickNextStep={() => setStep('WAITING_FOR_EMAIL_LINK')}
        />
      )}
      {step === 'WAITING_FOR_EMAIL_LINK' && <AuthModalCheckEmailStep />}
    </ModalWithOverlay>
  );
}
