import { twMerge } from 'tailwind-merge';
import Loader from './Loader';

const baseStyles = {
  solid:
    'group inline-flex items-center justify-center rounded-full py-2 px-8 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    'group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none',
};

// @apply select-none
// @apply px-8 py-2;
// @apply text-sm font-medium;
// @apply shadow-md transition;
// @apply bg-primary hover:bg-darkGray text-white;

const variantStyles = {
  solid: {
    primary:
      'bg-primary text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-primary',
    white:
      'bg-white text-primary hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white',
    orange:
      'bg-orange text-white hover:text-slate-100 hover:bg-darkOrange active:bg-darkOrange active:text-lightOrange focus-visible:outline-orange',
  },
  outline: {
    primary:
      'ring-blue-400 text-blue-400 hover:text-primary hover:ring-primary active:bg-slate-100 active:text-blue-900 focus-visible:outline-primary focus-visible:ring-slate-300',
    white:
      'ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white',
    orange:
      'ring-orange text-orange hover:text-orange hover:ring-orange active:bg-slate-100 active:text-darkOrange focus-visible:outline-orange focus-visible:ring-slate-300',
  },
};

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  variant?: 'solid' | 'outline';
  color?: 'primary' | 'white' | 'orange';
  disabled?: boolean;
  isLoading?: boolean;
  title: string;
  buttonClassName?: string;
  textClassName?: string;
}

export default function Button({
  onClick,
  variant = 'solid',
  color = 'primary',
  disabled,
  isLoading,
  title,
  buttonClassName,
  textClassName,
  ...props
}: Props) {
  buttonClassName = twMerge(
    baseStyles[variant],
    variantStyles[variant][color],
    (disabled || isLoading) && 'opacity-50 cursor-not-allowed',
    buttonClassName,
  );

  return (
    <button
      onClick={onClick}
      className={buttonClassName}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <Loader size={'small'} />}
      <p className={twMerge('m-0', textClassName)}>{title}</p>
    </button>
  );
}
