import GpsCoordinates from '../types/GpsCoordinates';
import { BoundedRectangle } from '../types/BoundedRectangle';

export const TORONTO_GPS_COORDINATES: GpsCoordinates = {
  latitude: 43.67,
  longitude: -79.3895,
};

export const DEFAULT_TORONTO_BOUNDED_RECTANGLE: BoundedRectangle = [
  [-79.33702532699569, 43.75195336002227],
  [-79.44197467309064, 43.610086276528335],
];

export const DEFAULT_ZOOM_LEVEL = 11.5;
export const CLOSE_ZOOM_LEVEL = 16.5;
export const SELECTED_PLACE_ZOOM = 16;

export const MAP_FLYTO_SPEED = 2.5;

export const APPROX_METRES_TO_DEGREES_CONVERSION_FACTOR = 111_139; // 1 meter is approximately 0.00001 degrees
