import isEqual from 'lodash.isequal';
import { SearchFilters } from '../../types/SearchFilters';
import { defaultFilters } from '../../constants/searchFilters';

export default function removeDefaultSearchFilters(
  searchFilters: SearchFilters,
): SearchFilters {
  const searchFiltersWithDefaultsRemoved: SearchFilters = {};
  for (const key in searchFilters) {
    if (!isEqual(searchFilters[key], defaultFilters[key])) {
      searchFiltersWithDefaultsRemoved[key] = searchFilters[key];
    }
  }
  return searchFiltersWithDefaultsRemoved;
}
