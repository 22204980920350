'use client';

import {
  QueryClient,
  QueryClientProvider as LibQueryClientProvider,
} from '@th/react-query';
import { useRef } from 'react';

type Props = {
  children: React.ReactNode;
};

export default function QueryClientProvider({ children }: Props) {
  const queryClient = useRef(new QueryClient());

  return (
    <LibQueryClientProvider client={queryClient.current}>
      {children}
    </LibQueryClientProvider>
  );
}
