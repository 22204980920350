'use client';

import { SearchFilters } from '@th/shared/src/types/SearchFilters';
import React, { createContext, useCallback, useRef, useState } from 'react';
import omit from 'lodash.omit';
import {
  coreDefaultSearchFilters,
  defaultFilters,
} from '@th/shared/src/constants/searchFilters';
import numberOfNonDefaultFilters from '@th/shared/src/helpers/searchFilters/numberOfNonDefaultFilters';
import isEqual from 'lodash.isequal';
import { NlpSearchStatusModalHandle } from '../components/NlpSearchStatusModal';
import isDefinedAndNonNull from '@th/shared/src/utils/isDefinedAndNonNull';
import isSearchFiltersTransactionTypeSale from '@th/shared/src/helpers/searchFilters/isSearchFiltersTransactionTypeSale';
import {
  defaultPermitFilters,
  PermitFilters,
} from '@th/shared/src/types/PermitFilters';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import removeGeoJsonFeatureIfAreaIdExists from '@th/shared/src/helpers/searchFilters/removeGeoJsonFeatureIfAreaIdExists';
import removeDefaultSearchFilters from '@th/shared/src/helpers/searchFilters/removeDefaultSearchFilters';
import isEmpty from 'lodash.isempty';
import {
  defaultSchoolFilters,
  SchoolFilters,
} from '@th/shared/src/types/SchoolFilters';

const SEARCH_FILTERS_QUERY_PARAM_KEY = 'sf';

export type SearchContext = {
  searchFilters: SearchFilters;
  updateFilters: (filters: SearchFilters) => void;
  permitFilters: PermitFilters;
  updatePermitFilters: (filters: PermitFilters) => void;
  schoolFilters: SchoolFilters;
  updateSchoolFilters: (filters: SchoolFilters) => void;
  resetAndUpdateFiltersOnTopOfExistingCoreValues: (
    filters: SearchFilters,
  ) => void;
  toggleShowActive: () => void;
  toggleShowSoldOrLeased: () => void;
  isFiltersSetToDefault: boolean;
  numberOfFiltersSet: number;
  clearFilters: () => void;
  clearGeoFilters: () => void;
  isSaleTransactionType: boolean;
  isSearchFilterModalOpen: boolean;
  openSearchFilterModal: () => void;
  closeSearchFilterModal: () => void;
  nlpSearchStatusModalRef: React.MutableRefObject<NlpSearchStatusModalHandle>;
  openNlpSearchStatusModalToLoading: (nlpPhrase: string) => void;
  closeNlpSearchModal: () => void;
};

// ----------------------
// Business Logic
// ----------------------

export default function useSearchLogic() {
  const router = useRouter();
  const params = useSearchParams();
  const pathname = usePathname();

  let searchFiltersFromQueryParams = {};
  if (params.get(SEARCH_FILTERS_QUERY_PARAM_KEY)) {
    searchFiltersFromQueryParams = JSON.parse(
      params.get(SEARCH_FILTERS_QUERY_PARAM_KEY),
    );
  }

  const [searchFilters, setSearchFilters] = useState<SearchFilters>({
    ...coreDefaultSearchFilters,
    ...searchFiltersFromQueryParams,
  });
  const [permitFilters, setPermitFilters] =
    useState<PermitFilters>(defaultPermitFilters);
  const [schoolFilters, setSchoolFilters] =
    useState<SchoolFilters>(defaultSchoolFilters);

  // SearchFiltersModal
  const [isSearchFilterModalOpen, setIsSearchFilterModalOpen] = useState(false);
  function openSearchFilterModal() {
    setIsSearchFilterModalOpen(true);
  }
  function closeSearchFilterModal() {
    setIsSearchFilterModalOpen(false);
  }

  // NlpSearchStatusModal
  const nlpSearchStatusModalRef = useRef<NlpSearchStatusModalHandle>();
  function openNlpSearchStatusModalToLoading(nlpPhrase: string) {
    nlpSearchStatusModalRef.current.openWithLoadingState(nlpPhrase);
  }
  function closeNlpSearchModal() {
    nlpSearchStatusModalRef.current.close();
  }

  function updateFilters(newFilters: SearchFilters) {
    const updatedFilters = { ...searchFilters, ...newFilters };
    setSearchFiltersAlongWithQueryParam(updatedFilters);
  }

  const updatePermitFilters = useCallback((filters: PermitFilters) => {
    setPermitFilters(prevFilters => ({ ...prevFilters, ...filters }));
  }, []);

  const updateSchoolFilters = useCallback((filters: SchoolFilters) => {
    setSchoolFilters(prevFilters => ({ ...prevFilters, ...filters }));
  }, []);

  function resetAndUpdateFiltersOnTopOfExistingCoreValues(
    filters: SearchFilters,
  ) {
    const updatedFilters = {
      ...searchFilters,
      showSoldOrLeased: searchFilters.showSoldOrLeased,
      showActive: searchFilters.showActive,
      transactionType: searchFilters.transactionType,
      maxListedDaysAgo: searchFilters.maxListedDaysAgo,
      maxSoldDaysAgo: searchFilters.maxSoldDaysAgo,
      ...filters,
    };
    setSearchFiltersAlongWithQueryParam(updatedFilters);
  }

  function toggleShowActive() {
    if (
      searchFilters.showActive === true &&
      searchFilters.showSoldOrLeased === false
    ) {
      // Do nothing. We always need one selected
      return;
    }
    updateFilters({
      showActive: !searchFilters.showActive,
    });
  }

  function toggleShowSoldOrLeased() {
    if (
      searchFilters.showSoldOrLeased === true &&
      searchFilters.showActive === false
    ) {
      // Do nothing. We always need one selected
      return;
    }
    updateFilters({
      showSoldOrLeased: !searchFilters.showSoldOrLeased,
    });
  }

  function isFiltersSetToDefault(): boolean {
    for (const key in omit(searchFilters, ['transactionType'])) {
      if (!isEqual(searchFilters[key], defaultFilters[key])) {
        return false;
      }
    }
    return true;
  }

  function numberOfFiltersSet(): number {
    return numberOfNonDefaultFilters(searchFilters);
  }

  function clearFilters() {
    const clearedFilters = {
      transactionType: searchFilters.transactionType, // don't change this one. keep what the user had selected
      showActive: defaultFilters.showActive,
      showSoldOrLeased: defaultFilters.showSoldOrLeased,
      maxSoldDaysAgo: defaultFilters.maxSoldDaysAgo,
    };
    setSearchFiltersAlongWithQueryParam(clearedFilters);
  }

  function clearGeoFilters() {
    if (
      !isDefinedAndNonNull(searchFilters.geographicAreas) ||
      searchFilters.geographicAreas.length === 0
    )
      return;

    const clearedFilters = omit(searchFilters, ['geographicAreas']);
    setSearchFiltersAlongWithQueryParam(clearedFilters);
  }

  function addSearchFilterToQueryParams(searchFilters: SearchFilters) {
    const searchFiltersWithoutGeoJsonFeature =
      removeGeoJsonFeatureIfAreaIdExists(searchFilters);
    const nonDefaultSearchFilters = removeDefaultSearchFilters(
      searchFiltersWithoutGeoJsonFeature,
    );
    const stringifiedSearchFilters = JSON.stringify(nonDefaultSearchFilters);
    const oldParams = new URLSearchParams(params.toString());
    oldParams.delete(SEARCH_FILTERS_QUERY_PARAM_KEY);
    if (!isEmpty(nonDefaultSearchFilters)) {
      oldParams.set(SEARCH_FILTERS_QUERY_PARAM_KEY, stringifiedSearchFilters);
    }
    router.replace(`${pathname}?${oldParams.toString()}`);
  }

  function setSearchFiltersAlongWithQueryParam(updatedFilters: SearchFilters) {
    setSearchFilters(updatedFilters);
    addSearchFilterToQueryParams(updatedFilters);
  }

  const isSaleTransactionType =
    isSearchFiltersTransactionTypeSale(searchFilters);

  return {
    searchFilters,
    updateFilters,
    permitFilters,
    updatePermitFilters,
    schoolFilters,
    updateSchoolFilters,
    resetAndUpdateFiltersOnTopOfExistingCoreValues,
    toggleShowActive,
    toggleShowSoldOrLeased,
    isFiltersSetToDefault: isFiltersSetToDefault(),
    numberOfFiltersSet: numberOfFiltersSet(),
    clearFilters,
    clearGeoFilters,
    isSaleTransactionType,
    isSearchFilterModalOpen,
    openSearchFilterModal,
    closeSearchFilterModal,
    nlpSearchStatusModalRef,
    openNlpSearchStatusModalToLoading,
    closeNlpSearchModal,
  };
}

// ----------------------
// BoilerPlate below
// ----------------------

const SearchContext = createContext<SearchContext | undefined>(undefined);

function SearchContextProvider({ children }) {
  const searchContext = useSearchLogic();
  return (
    <SearchContext.Provider value={searchContext}>
      {children}
    </SearchContext.Provider>
  );
}

function useSearch() {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchContextProvider');
  }
  return context;
}

export { SearchContextProvider, useSearch };
