import { UtmParams } from './UtmParams';

export type AnalyticsEvent = AnalyticsEventInfo & {
  id: string;
  createdAt: string;
};

export type AnalyticsEventInfo = AnalyticsEventBaseInfo &
  UtmParams & {
    deviceId: string;
  };

export type AnalyticsEventBaseInfo = {
  action: AnalyticsEventAction;
  type: AnalyticsEventType;
  setting?: string;
  webUrl?: string;
  mobileScreen?: string;
  experimentVariant?: string;
  otherData?: object;
};

export enum AnalyticsEventAction {
  // Screen view
  viewed_screen = 'viewed_screen',

  // Login
  logged_in_as_user = 'logged_in_as_user',
  logged_in_as_agent = 'logged_in_as_agent',

  // Signup button click
  clicked_signup_button_in_navbar_menu = 'clicked_signup_button_in_navbar_menu',
  clicked_signup_button_in_unauthenticated_carousel_overlay = 'clicked_signup_button_in_unauthenticated_carousel_overlay',
  clicked_signup_button_in_guessing_game_success_screen = 'clicked_signup_button_in_guessing_game_success_screen',
  clicked_signup_button_in_sticky_footer = 'clicked_signup_button_in_sticky_footer',
  clicked_signup_button_in_signin_card = 'clicked_signup_button_in_signin_card',

  // Login button click
  clicked_login_button_in_navbar_menu = 'clicked_login_button_in_navbar_menu',

  // Download button click
  clicked_download_button_in_map_footer = 'clicked_download_button_in_map_footer',
  clicked_download_button_in_navbar = 'clicked_download_button_in_navbar',

  // App store link click
  clicked_app_store_link_for_ios = 'clicked_app_store_link_for_ios',
  clicked_app_store_link_for_android = 'clicked_app_store_link_for_android',

  // Home valuation form step
  completed_home_valuation_form_step_address = 'completed_home_valuation_form_step_address',
  completed_home_valuation_form_step_property_details = 'completed_home_valuation_form_step_property_details',
  completed_home_valuation_form_step_contact_info = 'completed_home_valuation_form_step_contact_info',
  completed_home_valuation_form_step_continue_browsing = 'completed_home_valuation_form_step_continue_browsing',

  // Home valuation form completion
  completed_home_valuation_form = 'completed_home_valuation_form',

  // Property inquiry click
  clicked_property_inquiry_button_in_sticky_footer = 'clicked_property_inquiry_button_in_sticky_footer',

  // Property inquiry submit
  submitted_property_inquiry = 'submitted_property_inquiry',

  // Market Stats - searched a phrase
  searched_market_stats = 'searched_market_stats',

  // Suggested Filter Row - clicked a filter
  clicked_suggested_filter = 'clicked_suggested_filter',

  // Cashback button click - mobile app
  clicked_cashback_button_in_listing_details_screen_bottom_button = 'clicked_cashback_button_in_listing_details_screen_bottom_button',
  clicked_cashback_button_in_cashback_cta_card = 'clicked_cashback_button_in_cashback_cta_card',
  clicked_cashback_button_in_carousel_badge = 'clicked_cashback_button_in_carousel_badge',

  // Cashback button click - web
  clicked_cashback_button_in_cashback_cta_card_web = 'clicked_cashback_button_in_cashback_cta_card_web',
  clicked_cashback_button_in_listing_details_webpage_bottom_button = 'clicked_cashback_button_in_listing_details_webpage_bottom_button',

  // Membership actions - mobile app
  clicked_membership_join_button_on_listing_cashback_screen = 'clicked_membership_join_button_on_listing_cashback_screen',
  clicked_membership_learn_more_button_on_listing_cashback_screen = 'clicked_membership_learn_more_button_on_listing_cashback_screen',
  completed_membership_form_on_become_member_form_screen = 'completed_membership_form_on_become_member_form_screen',
  clicked_membership_learn_more_button_on_become_member_form_screen = 'clicked_membership_learn_more_button_on_become_member_form_screen',
  clicked_membership_join_button_on_learn_more_screen = 'clicked_membership_join_button_on_learn_more_screen',

  // Membership actions - web
  clicked_membership_join_button_in_main_header_section_on_cashback_landing_page_web = 'clicked_membership_join_button_in_main_header_section_on_cashback_landing_page_web',
  clicked_membership_join_button_in_how_it_works_section_on_cashback_landing_page_web = 'clicked_membership_join_button_in_how_it_works_section_on_cashback_landing_page_web',
  clicked_membership_join_button_in_testimonials_section_on_cashback_landing_page_web = 'clicked_membership_join_button_in_testimonials_section_on_cashback_landing_page_web',
  clicked_membership_join_button_in_member_benefits_section_on_cashback_landing_page_web = 'clicked_membership_join_button_in_member_benefits_section_on_cashback_landing_page_web',
  clicked_membership_join_button_in_faq_section_on_cashback_landing_page_web = 'clicked_membership_join_button_in_faq_section_on_cashback_landing_page_web',
  clicked_cashback_button_in_cashback_flow_step_1_web = 'clicked_cashback_button_in_cashback_flow_step_1_web',
  clicked_membership_join_button_in_cashback_flow_step_1_web = 'clicked_membership_join_button_in_cashback_flow_step_1_web',
  clicked_membership_learn_more_button_in_cashback_flow_step_1_web = 'clicked_membership_learn_more_button_in_cashback_flow_step_1_web',
  clicked_membership_learn_more_button_in_cashback_flow_step_2_web = 'clicked_membership_learn_more_button_in_cashback_flow_step_2_web',
  completed_membership_form_on_become_member_form_screen_web = 'completed_membership_form_on_become_member_form_screen_web',
}

export enum AnalyticsEventType {
  screen_view = 'screen_view',
  login = 'login',
  signup_button_click = 'signup_button_click',
  login_button_click = 'login_button_click',
  download_button_click = 'download_button_click',
  app_store_link_click = 'app_store_link_click',
  home_valuation_form_step = 'home_valuation_form_step',
  home_valuation_form_completion = 'home_valuation_form_completion',
  property_inquiry_click = 'property_inquiry_click',
  property_inquiry_submission = 'property_inquiry_submission',
  search = 'search',
  suggested_filter_click = 'suggested_filter_click',
  cashback_button_click = 'cashback_button_click',
  membership_join_click = 'membership_join_click',
  membership_learn_more_click = 'membership_learn_more_click',
  membership_form_completed = 'membership_form_completed',
}
