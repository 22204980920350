import omit from 'lodash.omit';
import isEqual from 'lodash.isequal';
import { defaultFilters } from '../../constants/searchFilters';
import { SearchFilters } from '../../types/SearchFilters';

export default function numberOfNonDefaultFilters(
  searchFilters: SearchFilters,
): number {
  const changedFilters = Object.keys(
    omit(searchFilters, ['transactionType']),
  ).filter(
    key =>
      searchFilters[key] !== undefined &&
      !isEqual(searchFilters[key], defaultFilters[key]),
  );
  return changedFilters.length;
}
