import { Units } from '../../types/Units';
import { User } from '../../types/User';
import { Agent, AgentObject } from '../../types/Agent';
import { AgentVerificationStatus } from '../../types/AgentVerificationStatus';

export type RegularUserOrAgentObject = {
  id?: string;
  firebaseId?: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
  preferredUnits?: Units;

  // Agent attributes that are potentially present
  agentId?: string;
  username?: string;
  legalName?: string;
  brokerageName?: string;
  brokeragePhoneNumber?: string;
  verificationStatus?: AgentVerificationStatus;
};

export default function getUserFromObject(
  userObject: RegularUserOrAgentObject,
): User | Agent {
  if (userObject.agentId && userObject.username) {
    return Agent.fromObject(userObject as AgentObject);
  } else {
    return User.fromObject(userObject);
  }
}
