const ONE_HUNDRED = 100;
const ONE_THOUSAND = 1_000;

export function roundToNearestThousand(num): number {
  return Math.round(num / ONE_THOUSAND) * ONE_THOUSAND;
}

export function roundToNearestHundred(num): number {
  return Math.round(num / ONE_HUNDRED) * ONE_HUNDRED;
}

export function average(numbers: Array<number>): number | undefined {
  if (numbers.length === 0) {
    return undefined;
  }

  return (
    numbers.reduce((previous, current) => previous + current, 0) /
    numbers.length
  );
}
