import getFirstName from '../helpers/names/getFirstName';
import getLastName from '../helpers/names/getLastName';
import { Units } from './Units';
import { Agent } from './Agent';

export enum UserAccountType {
  CashbackMember = 'CashbackMember',
  TraditionalClient = 'TraditionalClient',
}

export type UserObject = {
  id?: string;
  firebaseId?: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
  preferredUnits?: Units;
  profileImageUri?: string;
  accountType?: UserAccountType | null;
};

export type UserRecord = UserObject;

export class User {
  id?: string;
  firebaseId?: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
  preferredUnits: Units;
  profileImageUri?: string;
  accountType: UserAccountType | null;

  static fromRecord(userRecord: UserRecord) {
    return new User(userRecord);
  }

  static fromObject(userObject: UserObject) {
    return new User(userObject);
  }

  constructor({
    id,
    firebaseId,
    email,
    name,
    phoneNumber,
    preferredUnits,
    profileImageUri,
    accountType,
  }: UserObject) {
    this.id = id;
    this.firebaseId = firebaseId;
    this.email = email;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.preferredUnits = preferredUnits || Units.Feet;
    this.profileImageUri = profileImageUri;
    this.accountType = accountType || null;
  }

  isAgent(): this is Agent {
    return false;
  }

  isVerifiedAgent(): this is Agent {
    return false;
  }

  isPendingAgent(): this is Agent {
    return false;
  }

  isDefaultAccount(): boolean {
    return !this.accountType;
  }

  isCashbackMember(): boolean {
    return this.accountType === UserAccountType.CashbackMember;
  }

  isTraditionalClient(): boolean {
    return this.accountType === UserAccountType.TraditionalClient;
  }

  firstName(): string | null {
    return getFirstName(this.name);
  }

  lastName(): string | null {
    return getLastName(this.name);
  }

  toObject(): UserObject {
    return {
      id: this.id,
      firebaseId: this.firebaseId,
      email: this.email,
      name: this.name,
      phoneNumber: this.phoneNumber,
      preferredUnits: this.preferredUnits,
      profileImageUri: this.profileImageUri,
      accountType: this.accountType,
    };
  }
}
