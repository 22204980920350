import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import ModalWithOverlay from '../ModalWithOverlay';
import { useSearch } from '../../contexts/SearchContext';
import { SearchFilterViewLevel } from '@th/shared/src/types/SearchFilters';
import Button from '../Button';
import SvgIcon from '../icons/SvgIcon';

type Props = {};

const SearchFilterView = dynamic(
  () => import('./searchFilterView/SearchFilterView'),
  { ssr: false },
);

export default function SearchFilterModal({}: Props) {
  const {
    updateFilters,
    searchFilters,
    isSearchFilterModalOpen,
    closeSearchFilterModal,
    isFiltersSetToDefault,
    clearFilters,
  } = useSearch();

  const [viewLevel, setViewLevel] = useState<SearchFilterViewLevel>('basic');

  return (
    <ModalWithOverlay
      isOpen={isSearchFilterModalOpen}
      onClose={closeSearchFilterModal}
      className="flex flex-1 flex-col "
      style={{ minWidth: '50%', maxHeight: '80vh' }}
    >
      <div className="flex flex-row justify-between py-5 sm:py-8 px-8 border-b">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Search Filters
        </h3>
        <button onClick={() => closeSearchFilterModal()} className="">
          <SvgIcon name={'HiX'} />
        </button>
      </div>
      <div className="flex flex-1 flex-col overflow-y-auto px-8 ">
        <SearchFilterView
          searchFilters={searchFilters}
          onChange={updateFilters}
          enableListAndSoldDateFilters={true}
          viewLevel={viewLevel}
          showFiltersWhenSupplied={true}
          isAdmin={false}
        />
        <div className="flex justify-center py-4">
          <button
            onClick={() => {
              if (viewLevel === 'basic') {
                setViewLevel('advanced');
              } else {
                setViewLevel('basic');
              }
            }}
          >
            <p className="font-bold text-primary">
              {viewLevel === 'basic' ? '+ More Filters' : '- Less Filters'}
            </p>
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-end px-8 py-5 sm:py-8 border-t">
        <Button
          title={'Reset'}
          disabled={isFiltersSetToDefault}
          variant={'outline'}
          buttonClassName={''}
          onClick={clearFilters}
        />
        <Button
          buttonClassName={'ml-3'}
          title={'Apply'}
          onClick={() => {
            closeSearchFilterModal();
          }}
        />
      </div>
    </ModalWithOverlay>
  );
}
