import {
  getDeviceFingerprintIdFromStorage,
  setDeviceFingerprintIdInStorage,
} from './localStorage/deviceFingerprintId';
import { v4 as uuidv4 } from 'uuid';

const FINGERPRINT_JS_CUSTOM_SUBDOMAIN = 'https://fp.tophouse.com'; // Details: https://dev.fingerprint.com/docs/subdomain-integration

export default async function getAndStoreDeviceFingerprintId(): Promise<string> {
  let fingerprintId;

  try {
    const storedFingerprintId = getDeviceFingerprintIdFromStorage();

    if (storedFingerprintId) return storedFingerprintId;

    const { default: FingerprintJS } = await import(
      '@fingerprintjs/fingerprintjs-pro'
    );
    const fpPromise = FingerprintJS.load({
      apiKey: process.env.NEXT_PUBLIC_FINGERPRINT_JS_API_KEY,
      endpoint: FINGERPRINT_JS_CUSTOM_SUBDOMAIN,
    });

    const fp = await fpPromise;
    const { visitorId } = await fp.get();

    fingerprintId = visitorId;
  } catch (err) {
    fingerprintId = uuidv4();
  }

  setDeviceFingerprintIdInStorage(fingerprintId);
  return fingerprintId;
}
