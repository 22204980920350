const APP_URLS = {
  production: 'https://api.tophouse.com',
  development: 'http://localhost:3000',
  preview: 'https://api.tophouse.dev',
  test: null,
};

const appUrl =
  APP_URLS[process.env.NEXT_PUBLIC_VERCEL_ENV] ||
  APP_URLS[process.env.NODE_ENV] ||
  APP_URLS.development;

export default appUrl;
