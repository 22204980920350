import { z } from 'zod';
import { SchoolLevel, SchoolType } from './School';

export const SchoolFiltersSchema = z.object({
  types: z.array(z.nativeEnum(SchoolType)).optional().catch(undefined),
  levels: z.array(z.nativeEnum(SchoolLevel)).optional().catch(undefined),
  minRating: z.coerce.number().optional().catch(undefined),
});

export type SchoolFilters = z.infer<typeof SchoolFiltersSchema>;

export const defaultSchoolFilters: SchoolFilters = {
  types: [SchoolType.Public, SchoolType.Catholic],
  levels: [SchoolLevel.Elementary, SchoolLevel.Secondary],
  minRating: null,
};
