import isAxiosError from '../../types/typeGuards/isAxiosError';
import isFirebaseError from '../../types/typeGuards/isFirebaseError';

export default function logAxiosError(
  label: string,
  err: any,
  payload?: any,
  callback?: (errMessage: string) => void,
): void {
  if (isAxiosError(err)) {
    let errMessage;

    if (err.response) {
      errMessage = `${err.response.status}`;
      if (isFirebaseError(err.response.data)) {
        errMessage += ` (${err.response.data.code})`;
      } else if (typeof err.response.data === 'string') {
        errMessage += ` (${err.response.data})`;
      }
      errMessage += ` (${label})`;
      if (payload) {
        errMessage += `\nPayload:\n${JSON.stringify(payload)}`;
      }
    } else {
      errMessage = err.message;
    }

    // append the request method and url to all error messages.
    // Note: we may want to undo this step. I'm just trying to understand the Network Error that keeps happening on Bugsnag
    errMessage = `${err.request._method} ${err.request._url}\n${errMessage}`;

    console.error(errMessage);
    if (callback) {
      callback(errMessage);
    }
  }
}
