// @deprecated Jun 1 2023
export enum SoldDateFilterLabels {
  'Any' = 'Any',
  'OneDay' = '1 Day',
  'ThreeDays' = '3 Days',
  'SevenDays' = '7 Days',
  'ThirtyDays' = '30 Days',
  'ThreeMonths' = '3 Months',
  'SixMonths' = '6 Months',
  'OneYear' = '1 Year',
  'TwoYears' = '2 Years',
}
