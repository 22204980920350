import getUtmParamsFromUrl from '@th/shared/src/helpers/urls/getUtmParamsFromUrl';
import stripQueryParamsFromUrl from '@th/shared/src/helpers/urls/stripQueryParamsFromUrl';
import {
  AnalyticsEventAction,
  AnalyticsEventType,
} from '@th/shared/src/types/AnalyticsEvent';
import { useEffect } from 'react';
import { setUtmParamsInStorage } from '../helpers/localStorage/utmParams';
import logEvent from '../helpers/mutations/logEvent';
import upsertDevice from '../helpers/mutations/upsertDevice';

export default function useSaveUtmParamsToLocalStorageAndCreateInitialAnalyticsEvent() {
  useEffect(() => {
    async function setUtmParamsAndFireAnalytics() {
      const url = window.location.href;
      const utmParams = getUtmParamsFromUrl(url);

      if (utmParams) {
        setUtmParamsInStorage(utmParams);
      }
      await upsertDevice();
      logEvent({
        action: AnalyticsEventAction.viewed_screen,
        type: AnalyticsEventType.screen_view,
        webUrl: stripQueryParamsFromUrl(url),
      });
    }

    setUtmParamsAndFireAnalytics();
  }, []);
}
