'use client';

import React, { createContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../utils/firebase';
import createOrFetchCurrentUser from '@th/frontend-shared/src/helpers/mutations/createOrFetchCurrentUser';
import { User } from '@th/shared/src/types/User';
import { adminEmails } from '@th/shared/src/constants/admins';

export type UserContext = {
  refetchUser: () => Promise<User | null>;
  user: User | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
};

// ----------------------
// Business Logic
// ----------------------

export default function useUserLogic() {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      refetchUser();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  async function refetchUser() {
    const user = await createOrFetchCurrentUser(auth.currentUser);
    setUser(user);
    return user;
  }

  const isLoggedIn = Boolean(user);
  const isAdmin = isLoggedIn && adminEmails.includes(user.email);

  return {
    refetchUser,
    user,
    isLoggedIn,
    isAdmin,
  };
}

// ----------------------
// BoilerPlate below
// ----------------------

const UserContext = createContext<UserContext | undefined>(undefined);

function UserContextProvider({ children }) {
  const userContext = useUserLogic();
  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserContextProvider');
  }
  return context;
}

export { UserContextProvider, useUser };
