'use client';

import { MapProvider, useMap as useMapGL } from 'react-map-gl';
import React, { createContext, useState } from 'react';
import GpsCoordinates from '@th/shared/src/types/GpsCoordinates';
import { BoundedRectangle } from '@th/shared/src/types/BoundedRectangle';
import {
  DEFAULT_TORONTO_BOUNDED_RECTANGLE,
  DEFAULT_ZOOM_LEVEL,
  CLOSE_ZOOM_LEVEL,
  MAP_FLYTO_SPEED,
} from '@th/shared/src/constants/map';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { useSearch } from './SearchContext';
import { Place } from '@th/shared/src/types/Place';
import round from '@th/shared/src/helpers/round';

const BUILDING_PERMITS_QUERY_PARAM_KEY = 'buildingPermits';
const SCHOOLS_QUERY_PARAM_KEY = 'schools';

type MapContext = {
  focusCameraTo: (coordinates: GpsCoordinates) => void;
  focusCameraToBounds: (bounds: BoundedRectangle) => void;
  focusCameraAndSetPinTo: (coordinates: GpsCoordinates) => void;
  pinCoordinates: null | GpsCoordinates;
  setPinCoordinates: (coordinates: GpsCoordinates) => void;
  cameraCurrentState: { visibleBounds: BoundedRectangle; zoomLevel: number };
  zoomOut: () => void;
  updateCameraCurrentState: () => void;
  isPermitsLayerActive: boolean;
  togglePermitsLayer: () => void;
  isSchoolsLayerActive: boolean;
  toggleSchoolsLayer: () => void;
  areaOverlay: Place | null;
  clearAreaOverlay: () => void;
  updateAreaOverlay: (areaOverlay: Place) => void;
};

// ----------------------
// Business Logic
// ----------------------

function useMapLogic() {
  const { mainMap } = useMapGL();
  const { clearGeoFilters } = useSearch();

  const [pinCoordinates, setPinCoordinates] = useState<null | GpsCoordinates>(
    null,
  );
  const [cameraCurrentState, setCameraCurrentState] = useState<{
    visibleBounds: BoundedRectangle;
    zoomLevel: number;
  }>({
    visibleBounds: DEFAULT_TORONTO_BOUNDED_RECTANGLE,
    zoomLevel: DEFAULT_ZOOM_LEVEL,
  });
  const [areaOverlay, setAreaOverlay] = useState<Place | null>(null);
  const router = useRouter();
  const params = useSearchParams();
  const pathname = usePathname();

  const isPermitsLayerActiveDueToQueryParams = Boolean(
    params.get(BUILDING_PERMITS_QUERY_PARAM_KEY),
  );
  const [isPermitsLayerActive, setIsPermitsLayerActive] = useState(
    isPermitsLayerActiveDueToQueryParams,
  );

  const isSchoolsLayerActiveDueToQueryParams = Boolean(
    params.get(SCHOOLS_QUERY_PARAM_KEY),
  );
  const [isSchoolsLayerActive, setIsSchoolsLayerActive] = useState(
    isSchoolsLayerActiveDueToQueryParams,
  );

  function focusCameraTo(coordinates: GpsCoordinates) {
    /*
     Here we clear the geo filters, since we want to show the user listings on the map.
     eg. user has a geo filter for Beaches set, then searches 99 Ronan ave. Ze should see dots around 99 ronan, otherwise, something seems broken.
     Note that we don't do this for the focusCameraToBounds function because that one is currently used _by_ the geoFilters to set the camera.
     This feels a bit brittle, maybe there is a better way?
    */
    clearGeoFilters();

    if (!mainMap) return;

    mainMap.flyTo({
      center: [coordinates.longitude, coordinates.latitude],
      zoom: CLOSE_ZOOM_LEVEL,
      speed: MAP_FLYTO_SPEED,
    });
  }

  function focusCameraToBounds(bounds: BoundedRectangle) {
    if (!mainMap) return;

    const sw = bounds[0] as [number, number];
    const ne = bounds[1] as [number, number];
    const BOUNDS_PADDING_PIXELS = 150;
    const camera = mainMap.cameraForBounds([...sw, ...ne], {
      padding: BOUNDS_PADDING_PIXELS,
    });
    if (camera) {
      mainMap.flyTo({
        center: camera.center,
        zoom: camera.zoom,
        speed: MAP_FLYTO_SPEED,
      });
    }
  }

  function focusCameraAndSetPinTo(coordinates: GpsCoordinates) {
    focusCameraTo(coordinates);
    setPinCoordinates(coordinates);
  }

  function zoomOut() {
    if (!mainMap) return;
    mainMap.zoomTo(cameraCurrentState.zoomLevel - 4);
  }

  function togglePermitsLayer() {
    // Update the state
    const newIsPermitsLayerActive = !isPermitsLayerActive;
    setIsPermitsLayerActive(newIsPermitsLayerActive);

    // Update the url query params
    const oldParams = new URLSearchParams(params.toString());
    if (newIsPermitsLayerActive) {
      oldParams.set(BUILDING_PERMITS_QUERY_PARAM_KEY, 't');
    } else {
      oldParams.delete(BUILDING_PERMITS_QUERY_PARAM_KEY);
    }
    router.replace(`${pathname}?${oldParams.toString()}`);
  }

  function toggleSchoolsLayer() {
    // Update the state
    const newIsSchoolsLayerActive = !isSchoolsLayerActive;
    setIsSchoolsLayerActive(newIsSchoolsLayerActive);

    // Update the url query params
    const oldParams = new URLSearchParams(params.toString());
    if (newIsSchoolsLayerActive) {
      oldParams.set(SCHOOLS_QUERY_PARAM_KEY, 't');
    } else {
      oldParams.delete(SCHOOLS_QUERY_PARAM_KEY);
    }
    router.replace(`${pathname}?${oldParams.toString()}`);
  }

  function overwriteSearchParams(lat: number, lng: number, zoom: number) {
    const oldParams = new URLSearchParams(params.toString());
    // https://xkcd.com/2170/
    oldParams.set('lat', round(lat, 4).toString());
    oldParams.set('lng', round(lng, 4).toString());
    oldParams.set('zoom', round(zoom, 1).toString());
    router.replace(`${pathname}?${oldParams.toString()}`);
  }

  function updateCameraCurrentState() {
    if (!mainMap) return;
    const visibleBounds = mainMap.getBounds().toArray() as BoundedRectangle;
    const center = mainMap.getCenter();
    const zoomLevel = mainMap.getZoom();
    setCameraCurrentState({
      visibleBounds,
      zoomLevel,
    });
    overwriteSearchParams(center.lat, center.lng, zoomLevel);
  }

  function clearAreaOverlay() {
    setAreaOverlay(null);
  }

  function updateAreaOverlay(overlay: Place) {
    setAreaOverlay(overlay);
  }

  return {
    focusCameraTo,
    focusCameraToBounds,
    focusCameraAndSetPinTo,
    pinCoordinates,
    setPinCoordinates,
    cameraCurrentState,
    zoomOut,
    updateCameraCurrentState,
    isPermitsLayerActive,
    togglePermitsLayer,
    isSchoolsLayerActive,
    toggleSchoolsLayer,
    areaOverlay,
    clearAreaOverlay,
    updateAreaOverlay,
  };
}

// ----------------------
// BoilerPlate below
// ----------------------

const MapContext = createContext<MapContext | undefined>(undefined);

function MapContextProvider({ children }) {
  const mapContext = useMapLogic();
  return (
    <MapContext.Provider value={mapContext}>{children}</MapContext.Provider>
  );
}

function useMap() {
  const context = React.useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMap must be used within a MapContextProvider');
  }
  return context;
}

export { MapContextProvider, useMap };
