import { STORAGE_DEVICE_FINGERPRINT_ID_KEY } from '../../constants/localStorage';

export function setDeviceFingerprintIdInStorage(deviceFingerprintId: string) {
  window.localStorage.setItem(
    STORAGE_DEVICE_FINGERPRINT_ID_KEY,
    deviceFingerprintId,
  );
}

export function getDeviceFingerprintIdFromStorage(): string | null {
  return window.localStorage.getItem(STORAGE_DEVICE_FINGERPRINT_ID_KEY) || null;
}
