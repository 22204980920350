import isDefined from '../../utils/isDefined';
import { RepliersListingTransactionType } from '../../types/repliers/RepliersListingTransactionType';
import { SearchFilters } from '../../types/SearchFilters';

export default function isSearchFiltersTransactionTypeSale(
  searchFilters: SearchFilters,
): boolean {
  return isDefined(searchFilters.transactionType)
    ? searchFilters.transactionType === RepliersListingTransactionType.Sale
    : true;
}
