import AxiosClient from '../../utils/AxiosClient';
import { User } from '@th/shared/src/types/User';
import { Agent } from '@th/shared/src/types/Agent';
import getUserFromObject from '@th/shared/src/helpers/users/getUserFromObject';
import logAxiosError from '../../utils/logAxiosError';

export default async function createOrFetchCurrentUser(
  firebaseUser,
  options?: {
    onError?: (err: Error) => void;
  },
) {
  const endpoint = `/api/v2/users/current`;

  if (!firebaseUser) return;

  try {
    const response = await AxiosClient.post(endpoint);
    return getUserFromResponseData(response.data);
  } catch (err) {
    logAxiosError('createOrFetchCurrentUser', err);
    options?.onError && options.onError(err);
  }
}

function getUserFromResponseData(data): User | Agent | null {
  const user = data?.user ? getUserFromObject(data.user) : null;
  return user;
}
