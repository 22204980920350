import { z } from 'zod';
import { BuildingPermitStage } from './BuildingPermitStage';

export enum PermitCategoryFilterOptionLabels {
  SingleFamily = 'Single Family',
  HighRise = 'High-Rise',
  MultiUnit = 'Multi-Unit',
  AdditionalDwellingUnits = 'Additional Dwelling Units',
  Additions = 'Home Additions',
}

export const PermitFiltersSchema = z.object({
  stages: z
    .array(z.nativeEnum(BuildingPermitStage))
    .optional()
    .catch(undefined),
  categories: z
    .array(z.nativeEnum(PermitCategoryFilterOptionLabels))
    .optional()
    .catch(undefined),
  hasSeveredLot: z.boolean().nullable().optional().catch(undefined),
});

export type PermitFilters = z.infer<typeof PermitFiltersSchema>;

export const defaultPermitFilters: PermitFilters = {
  stages: [BuildingPermitStage.Applied, BuildingPermitStage.Issued],
  categories: [
    PermitCategoryFilterOptionLabels.SingleFamily,
    PermitCategoryFilterOptionLabels.MultiUnit,
    PermitCategoryFilterOptionLabels.HighRise,
  ],
  hasSeveredLot: null,
};
