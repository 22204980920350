import logAxiosErrorShared from '@th/shared/src/utils/errors/logAxiosError';

export default function logAxiosError(
  label: string,
  err: any,
  payload?: any,
): void {
  logAxiosErrorShared(label, err, payload, errMessage => {
    // do nothing
  });
}
