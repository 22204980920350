export default function getLastName(
  name: string | null | undefined,
): string | null {
  if (!name) return null;

  const nameWords = name.split(' ').filter(word => Boolean(word));

  if (nameWords.length === 1) {
    return null;
  } else {
    return nameWords.slice(nameWords.length - 1, nameWords.length).join(' ');
  }
}
